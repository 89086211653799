import Vue from 'vue';

export default ({ app }) => {
  if (!Vue.prototype.$locale) {
    Object.defineProperty(Vue.prototype, '$locale', {
      get() {
        return app.i18n.locale;
      },
      set(locale) {
        app.i18n.locale = locale;
      },
    });
  }
  Vue.prototype.$localize = app.i18n;
};
