
import { rzContainer } from 'razlet-ui';

export default {
  components: {
    rzContainer, 
  },
  props: {
    error: {
      type: Object,
      default: null,
    },
  },
  data () {
    return {
      pageNotFound: 'Такая страница не найдена',
      otherError: 'Что-то пошло не так',
    };
  },
  head () {
    const title = this.error.statusCode === 404
      ? this.pageNotFound
      : this.otherError;

    return { title };
  },
};
