import Vue from 'vue';
import FilesSender from '~/api/files-sender';
import SuluSender from '~/api/sulu-sender';

export const strict = false;

const filesSender = new FilesSender();
const suluSender = new SuluSender(true);

export const state = () => ({
  ua: /iPhone|iPad|iPod|Android|Windows Phone/.test(typeof window !== 'undefined' ? window.navigator.userAgent : ''),
  mobile: false,
});

export const getters = {
  isMobile(state) {
    return state.mobile;
  },
};

export const mutations = {
  setMobile(state, value) {
    state.mobile = value;
  },
};

export const actions = {
  async nuxtServerInit ({ dispatch }, { app, isDev, req }) {
    const currentLang = 'ru';
    Vue.prototype.$locale = currentLang;
    await app.i18n.setLocale(currentLang);
    await dispatch(
      'sulu/initSuluSender',
      {
        req,
        isDev,
        requests: [
          'getMenu',
          'airport/getAbout',
          'airport/getSitesList',
          'airport/getWeather',
        ],
      }
    );
  },
  // eslint-disable-next-line no-empty-pattern
  sendFiles({}, formData) {
    return filesSender.post('/files/upload/ajax/multi', formData);
  },
  // eslint-disable-next-line no-empty-pattern
  sendFeedback({}, data) {
    return suluSender.post('/feedback/send', data);
  },
};